
import { Component, Vue } from 'vue-property-decorator'
import TheAgreement from '@/components/agreement/TheAgreement.vue'

@Component({
  components: {
    TheAgreement
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class AgreementPage extends Vue {

}
